import React from 'react';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Contact from './Contact/Contact';

const ContactPage = () => {
    return (
        // <Layout>
        <>
        <SEO title="Spin　D&D　|　Contact" />
            <Contact />
        </>
        // </Layout>
    );
};
export default ContactPage